.input-wrap_select {

  .selectric {
    border: 1px solid $mainColor;
    background-color:  #f1f3f8;
    @extend .transition;
    .label {
      font-family: $secondFont;
      font-size: 14px;
      padding-left: 25px;
      margin-left: 0;
      line-height: 52px;
      height: 52px; } }

  .selectric-open {
    .selectric {
      border-color: $mainColorDark;
      background: #fff; } }

  .select-arrow {
    position: absolute;
    top: 50%;
    left: calc(100% - 30px);
    transform: translateY(-50%);
    width: 20px;
    height: 12px;
    background-image: url(../img/svg/select-arrow.svg);
    background-size: contain;
    background-position: center; } }

.selectric-items {
  background: #f1f3f8;
  li {
    font-size: 16px;
    padding-left: 25px;
    @extend .transition;
    &.selected, &.highlighted {
      background: #f1f3f8; }
    &:hover {
      color: $mainColor;
      background: #f1f3f8; } } }
