.header {
  display: flex;
  height: 117px;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  background-color: #fff;
  box-shadow: 0 4px 49px rgba(38, 54, 34, 0.43);
  @extend .transition;

  &__logo {
    display: flex;
    align-items: center;
    justify-content: flex-start; }

  &__menuBtn {
    margin-right: 15px; }

  &__menu {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

  &_login {
    width: 100%;
    position: relative;
    z-index: 3;
    .menuBtn {
      display: block;
      width: 31px;
      height: 23px; } } }


.menu {
  &__link {
    margin-right: 50px;
    line-height: 117px;
    &:last-of-type {
      margin-right: 0; } }

  &__btn {
    font-size: 18px;
    margin: 0 15px 0 50px;
    .button {
      max-width: 286px;
      width: 100%;
      box-sizing: content-box; }
    svg {
      width: 22px;
      height: 28px;
      fill: $mainColorLight;
      margin-right: 10px; }
    span {
      margin: 0 5px; }
    a {
      color: #000; }
    &:hover {
      svg {
        fill: #fff; }
      a {
        color: #fff; } }
    &:focus svg {
      fill: $mainColorLight; }
    &_exit {
      margin: 0;
      .button {
        min-width: 147px;
        width: auto;
        svg {
          margin-right: 0;
          margin-left: 10px; } } } }
  &__phone {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 36px;
    svg {
      margin-right: 20px;
      width: 27px;
      height: 28px;
      fill: $mainColorLight; } }
  &__avatar {
    margin-right: 20px; } }

.mobileReg {
  font-size: 13px; }

//--------------------------fixed header if scroll

.header_fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }


.header_fixed {
  height: 55px;
  .menuBtn {
    transform: scale(0.8); }
  .logoImg {
    width: 150px; }
  .avatar__logo {
    width: 40px;
    height: 40px;
    margin-right: 10px; }
  .avatar__name {
    font-size: 18px;
    &:after {
      top: calc(100% - 2px); } }
  .menu__btn_exit .button {
    height: 40px;
    min-width: 100px;
    font-size: 16px; } }


@include bc((max: 1279)) {
  .menu {
    &__link {
      margin-right: 20px; }
    &__btn {
      margin: 0 30px;
      &_exit {
        margin: 0; } }
    &__phone {
      font-size: 20px; } } }

@include bc((max: 991)) {
  .header {}

  .menu {
    &__links {
      display: none; }
    &__btn {
      margin: 0;
      &:hover svg {
        fill: $mainColor; }
      .button {
        height: 47px;
        width: 215px; }
      &_exit {
        .button {
          width: 123px; } } }
    &__phone {
      display: none; } } }

@include bc((max: 767)) {
  .header {
    height: 51px; }

  .menu {
    &__btn {
     span {
       display: none; }
     .button {
       width: auto;
       padding: 0;
       border: none; }
     &:hover svg {
      fill: $mainColorLight; }
     &_exit {
       display: none; } } }

  .header_fixed {
    .logoImg {
      width: 125px; }
    .avatar__name {
      font-size: 14px; } } }


@import "../blocks/_menuBtn";
@import "../blocks/_logoImg";
@import "../blocks/_link_line";
@import "../blocks/_avatar";
@import "../blocks/modal/modal";
@import "../blocks/_sortable";
