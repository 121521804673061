// footer press to bottom
.content-wrap {}
//  display: flex
//  flex-direction: column
//  height: 100%
//
//.content
//  flex: 1 0 auto
//
//footer
//  flex: 0 0 auto

.content.fixed {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content-wrapper {
    flex: 1 0 auto; }

  footer {
    flex: 0 0 auto; } }
