.link_line {
  position: relative;
  display: inline-block;
  font-size: 26px;
  color: #373d41;
  text-decoration: none;
  transition: .2s ease-in;
  letter-spacing: 0.17px;
  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    left: 50%;
    top: calc(100% - 4px);
    transform: translateX(-50%);
    background-color: #9eb670;
    transition: .2s ease-in; } }

.link_line:hover, .active {
  color: #9eb670;
  text-decoration: none;
  &:after {
    width: 100%; } }

.link_line:active {
  &:after {
    width: 105%; } }

.link_line:focus {
  color: #373d41;
  &:after {
    width: 0; } }

@include bc((max: 1279)) {
  .link_line {
    font-size: 20px; } }
