@mixin b($point) {
  @if $point == xs {
    @media screen and (max-width: 767px) { @content ; }
  }
  @else if $point == sm {
    @media (min-width: 768px) and (max-width: 991px) { @content ; }
  }
  @else if $point == md {
    @media (min-width: 992px) and (max-width: 1199px) { @content ; }
  }
  @else if $point == lg {
    @media screen and (min-width: 1200px) { @content ; }
  }
  @else if $point == tablet {
    @media screen and (max-width: 991px) { @content ; }
  }
  @else if $point == desktop {
    @media screen and (min-width: 992px) { @content ; }
  }
}

@mixin bc($map) {
  $directions: (min: min, max: max);
  $query: '';

  @each $direction, $breakpoint in $map {
    @if map_has_key($directions, $direction) {
      @if $query != '' {
        $query: $query + ') and (';
      }

      @if variable-exists(breakpoints) and map_has_key($breakpoints, $breakpoint) {
        $breakpoint: map_get($breakpoints, $breakpoint);
      }

      $query: $query + map_get($directions, $direction) + '-width: ' + $breakpoint + 'px';
    }
  }

  @if $query != '' {
    @media #{'(' + $query + ')'} {
      @content;
    }
  }
}
