.ui-datepicker {
  font-family: $mainFont; }

.ui-datepicker td span, .ui-datepicker td a {
  text-align: center; }

.ui-widget-header, .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  background-color: #f1f3f8; }


.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  background: $mainColor;
  border-color: $mainColor;
  color: #fff; }

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  background: $mainColorDark;
  border-color: $mainColorDark;
  color: #fff; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  background-image: url(../img/svg/triangle-play.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; }


.ui-datepicker .ui-datepicker-prev {
  transform: rotate(180deg); }
