.modal {
  &__btn {
    display: block;
    max-width: 320px;
    width: 100%;
    margin: 0 auto;
    font-size: 25px;
    color: #fff;
    background: $mainColor;
    text-transform: none;
    letter-spacing: 1.25px;
    &:focus {
      @extend .modal__btn; }
    &:hover, &:active {
      background-color: $mainColorDark;
      border-color: $mainColorDark; } } }


@include bc((max: 767)) {
  .modal {
    &__btn {
      max-width: 212px;
      height: 45px;
      font-size: 18px; } } }
