// =============================================================================
// String Replace
// =============================================================================

@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
}

  @return $string;
}

// =============================================================================
// Font Face
// =============================================================================

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
  eot: "?",
  svg: "#" + str-replace($name, " ", "_")
);

  $formats: (
  otf: "opentype",
  ttf: "truetype"
);

  @each $ext in $exts {
    $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
    $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
    $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
}

  @font-face {
    font-family: quote($name);
    font-style: $style;
    font-weight: $weight;
    src: $src;
}
}

.statistic {
  margin-bottom: 60px;
}

.item-statistic {
  padding: 0 20px 60px 20px;
  background-color: #f1f3f8;
  height: 164px;
  margin-bottom: 30px;
}

.item-statistic h4 {
  color: #9eb670;
  font-size: 26px;
  font-weight: 700;
  padding-top: 20px;
  height: 100px;
}

.item-statistic-value {
  color: #686868;
  font-size: 23px;
}

.item-statistic-block {
  padding: 0 30px;
}
