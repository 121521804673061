//-------------------------------fonts sizes

.small-S {
  font-size: 16px;
  line-height: 24px;
  @include bc((max: 767)) {
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.66px; } }

.medium-S {
  font-size: 22px;
  line-height: 32px;
  @include bc((max: 991)) {
    font-size: 14px;
    line-height: 18px; }
  @include bc((max: 767)) {
    font-size: 12px;
    letter-spacing: 0.66px; } }

.big-S {
  font-size: 26px;
  line-height: 32px;
  @include bc((max: 991)) {
    font-size: 20px;
    line-height: 24px; }
  @include bc((max: 767)) {
    font-size: 15px;
    line-height: 18px; } }

//-----------------------------------colors
.light-C {
  color: #97a0a5; }

.main-C {
  color: $mainColor; }

//---------------------------------some selection
.select-text {
  color: $mainColor;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: $mainFontColor;
    top: 100%;
    left: 0; } }

//----------------------------------position
.center {
  text-align: center; }
