.modal {
  &__close {
    position: absolute;
    top: 10px;
    left: calc(100% - 25px);
    cursor: pointer;
    svg {
      width: 14px;
      height: 14px;
      fill: #c8c7c7;
      @extend .transition; }
    &:hover svg {
      fill: $mainColor; }
    &:active svg {
      fill: $mainColorDark; } } }
