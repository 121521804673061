.modal {
  &__title {
    margin-bottom: 15px;
    font-size: 42px;
    font-weight:  700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    color: #373d41;
    letter-spacing: 2.1px; }
  &__title_status {
    margin-bottom: 15px;
    font-size: 28px;
    font-weight:  700;
    text-transform: uppercase;
    text-align: center;
    line-height: 1;
    color: #373d41;
    letter-spacing: 2.1px; }

  &__subTitle {
    font-size: 18px;
    line-height: 0.9;
    text-align: center;
    color: $mainColor;
    letter-spacing: 0.9px; } }

@include bc((max: 767)) {
  .modal {
    &__title {
      font-size: 26px;
      letter-spacing: 1.3px; }
    &__subTitle {
      font-size: 14px;
      letter-spacing: 0.7px; } } }
