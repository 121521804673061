@charset "UTF-8";
body {-webkit-text-size-adjust: none;-webkit-tap-highlight-color: transparent;-webkit-focus-ring-color: transparent;}

input,
textarea {-webkit-user-select: text;-moz-user-select: text;-ms-user-select: text;user-select: text;}



*[class^="col"]{
  box-sizing: border-box;
}

nav,
main,
aside,
figure,
footer,
header,
section,
summary,
article,
details,
figcaption {display: block;}

video,
audio,
canvas {display: inline-block;}

[hidden],
audio:not([controls]) {display: none;}

body {height: 100%;}

html {font-family: sans-serif;-webkit-text-size-adjust: 100%;-ms-text-size-adjust: 100%;text-size-adjust: 100%;}

a:focus {outline: thin dotted;}

input,
select,
button,
a:hover,
textarea,
a:active {outline: 0;}

img,
abbr,
input,
button,
iframe,
textarea {border: 0;}

svg:not(:root) {overflow: hidden;}

input,
button,
select,
textarea,
[type=search]::-webkit-search-decoration,
[type=search]::-webkit-search-cancel-button {-webkit-appearance: none;}

input,
button,
select,
textarea {font-size: 100%;font-family: inherit;-webkit-border-radius: 0;border-radius: 0;-webkit-transition: border linear .2s, box-shadow linear .2s, background linear .2s;-webkit-transition: border linear .2s, background linear .2s, -webkit-box-shadow linear .2s;transition: border linear .2s, background linear .2s, -webkit-box-shadow linear .2s;transition: border linear .2s, box-shadow linear .2s, background linear .2s;transition: border linear .2s, box-shadow linear .2s, background linear .2s, -webkit-box-shadow linear .2s;-moz-appearance: none;-webkit-appearance: none;appearance: none;-webkit-box-shadow: none;box-shadow: none;}

input,
button {line-height: normal;}

button,
label[for],
[type=reset],
[type=button],
[type=submit] {cursor: pointer;}

button,
[type=reset],
[type=button],
[type=submit] {-webkit-appearance: none;}

body,
[disabled] {cursor: default;}

input,
button,
select,
textarea,
[type=radio],
[type=checkbox] {-webkit-box-sizing: border-box;box-sizing: border-box;}

p,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
body,
input,
button,
select,
figure,
textarea,
figcaption {margin: 0;}

p,
ol,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
input,
button,
figure,
figcaption,
[type=radio],
[type=checkbox] {padding: 0;}

textarea {resize: none;overflow: auto;}

ol,
ul {list-style: none;}

img,
textarea {vertical-align: top;}

label {
  margin-bottom: 0;
}