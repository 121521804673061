.menuBtn {
  position: relative;
  cursor: pointer;
  display: none;
  overflow: hidden;
  span {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background-color: #64855b;
    transition: .4s ease;
    &:after, &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      top: 10px;
      left: 0;
      background-color: #64855b;
      transition: .4s ease; }
    &:before {
      top: -10px; } } }

.menuBtn.active {
  span {
    left: 100%;
    &:after, &:before {
      left: -100%;
      top: 0;
      transform: rotate(45deg); }
    &:before {
      transform: rotate(-45deg); } } }

@include bc((max: 991)) {
  .menuBtn {
    width: 31px;
    height: 23px;
    display: inline-block; } }

@include bc((max: 767)) {
  .menuBtn {
    width: 21px;
    height: 16px;
    span {
      &:after {
        top: 7px; }
      &:before {
        top: -7px; } } } }
