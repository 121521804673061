.footer {
  &__top {
    padding-top:  70px;
    padding-bottom: 60px;
    border-bottom: 1px solid $mainColor;
    text-align: center;
    &-phone {
      font-size: 36px; }

    .middle-el {
      border-right: 1px solid $mainColor;
      border-left: 1px solid $mainColor; } }

  &__topIcon {
    display: flex;
    height: 100px;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px; }

  &__bottomText {
    padding: 40px 40px 10px;
    text-align: center;
    font-size: 16px;
    font-weight: 300; }

  &__bottom_accaunt {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 40px 0;

    .footer__bottomText {
      max-width: 400px;
      padding: 0;
      text-align: right; } }

  &__bottomLinks {
    font-size: 16px;
    max-width: 200px;
    li {
      padding-bottom: 10px; }
    li:last-child {
      padding-bottom: 0; } }
  &__bottomLinksWrap {
    display: flex;
    align-items: flex-end;
    > .footer__bottomLinks:first-child {
      margin-right: 50px; } }
  &__bottomLinksWrapper {
    padding-bottom: 40px;

    & a {
      color: #9eb670;
      padding: 10px;

      &:hover {
        color: #64855b; } } } }

@include bc((max: 991)) {
  .footer {
    &__top {
      padding-top:  25px;
      padding-bottom: 25px;
      &-phone {
        font-size: 26px; } }

    &__topIcon {
      height: 70px; }

    &__bottomText {
      padding: 30px 0;
      font-size:  14px; }

    &__bottomLinks {
      font-size: 18px; } }

  .footer__top .row {
    > div:nth-child(1) .footer__topIcon img {
      width: 64px;
      height: auto; }
    > div:nth-child(2) .footer__topIcon img {
      width: 49px;
      height: auto; }
    > div:nth-child(3) .footer__topIcon img {
      width: 64px;
      height: auto; } } }



@include bc((max: 767)) {
  .footer {
    &__top {
      .middle-el {
        border: none; }
      &-phone {}
      font-size: 22px; }

    &__topIcon {
      height: auto;
      margin-bottom: 10px; }

    &__bottomLinks {
      font-size: 16px; } }

  .footer__top .row {
    > div:nth-child(1), > div:nth-child(2) {
      margin-bottom:  20px; }
    > div:nth-child(2) .footer__topIcon img {
      transform: translateX(-10px); } }

  .footer__bottomText {
    padding: 20px 0;
    font-size:  12px;
    line-height:  1.2; }

  .footer__bottom {
    &_accaunt {
      flex-direction: column;
      padding: 25px 0;
      .footer__bottomText {
        margin: 0 auto;
        text-align: center; } } }

  .footer__bottomLinksWrap {
    width:  100%;
    margin-bottom:  20px;
    justify-content: center; } }
