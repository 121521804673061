[off-canvas*=left] {
  top:  0;
  width: auto;
  position: fixed;
  padding-top: 55px;
  z-index: 2; }



[off-canvas] {
  background-color: $mainColorDark; }


@include bc((max: 991)) {
  [off-canvas*=left] {
    padding-top: 60px; }

  [canvas=container] {
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    overflow-y: scroll;
    //position: fixed
    //height: 100vh
    //position: fixed
    //position: absolute
    //left: 0
    //top: 0
 } }    //-webkit-overflow-scrolling: auto
