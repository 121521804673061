.input-add {
  display: flex;
  justify-content: space-between;
  &_clone {
    margin-top: 10px; }
  &__btn {
    width: 50px;
    margin-left: 15px;
    flex-grow: 1;
    text-align: center;
    font-size: 30px;
    line-height: 52px;
    background-color: $mainColor;
    color: #fff;
    @extend  .transition;
    &:focus {
      background-color: $mainColor; }
    &:hover {
      background-color: $mainColorDark; }
    &:active {
      transform: translateY(1px);
      background-color: $mainColorDark; }
    &_del {
      background-color: $errorColor;
      &:focus {
        background-color: $errorColor; }
      &:hover {
        background-color: rgba($errorColor, .9); }
      &:active {
        transform: translateY(1px);
        background-color: rgba($errorColor, .9); } } } }



@include bc((max: 768)) {
  .input-add {
    &__btn {
      line-height: 42px;
      width:  42px;
      margin-left: 10px; } } }
