/*-------  fix popup ios  ------------*/
body.modal-open {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0; }

.modal {
  -webkit-overflow-scrolling: auto; }
/*------------------------------------*/

.touch-title {
  display: inline-block; }
