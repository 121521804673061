@import "modal-smallText";

.modal-backdrop.fade {
  opacity: 0.4; }

.modal {

  .modal-dialog {
    margin: 200px auto 30px auto; }

  &__inner {
    max-width: 570px;
    background-color: #fff;
    position: relative;
    z-index: 2; }

  &__header {
    padding: 30px 100px 30px; }

  &__content {
    padding: 0 100px 30px; }

  &__registerPhone {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 30px 0;
    .modal__fieldWrap_select {
      flex-basis: 60px;
      margin-right: 10px; }
    .modal__fieldWrap_input {
      flex-grow: 1; } }

  &__securityText {
    margin-top: 20px;
    text-align: center;
    font-size: 16px; }

  &__sendKey {
    .modal__fieldWrap {
      margin: 20px 0 30px 0; } }

  &__changePayment {
    .modal__fieldWrap {
      margin: 20px 0; } }

  &__cardData {
    margin-top: 45px;
    > div:nth-child(2) {
      margin-bottom: 0; }
    > div:nth-child(3) {
      display: flex;
      justify-content: space-between;
      .modal__fieldWrap {
        width:  40%; } } }

  &__cardAccount {
    margin-top: 45px; }


  &__enter {
    .modal__btn {
      margin-top: 40px;
      &:focus {
        margin-top: 40px; } } }

  &__enterPhone {
    @extend .modal__registerPhone;
    margin-bottom: 10px; } }

@include bc((max: 579)) {
  .modal {

    .modal-dialog {
      margin: 100px auto 0 auto; }

    &__inner {
      max-width: 290px; }

    &__header {
      padding-top: 20px; }

    &__content {
      padding: 0 20px 20px; }

    &__success {
      padding-top: 10px;
      padding-bottom: 40px; }

    &__enter {}
    .modal__btn, .modal__btn:focus {
      margin-top: 20px; } } }

label[for="remember_me"] {
  margin-right: 10px;
  margin-left: 0; }

.modal .modal-dialog.modal-quick-start {
  width: 900px;
  max-width: 900px;
  margin: 50px auto 30px auto; }

.modal .modal-dialog.modal-quick-start .modal__content {
  min-height: 400px; }
.modal .modal-dialog.modal-quick-start .modal_step_footer {
  padding: 10px 10px; }

.modal .modal-dialog.modal-quick-start .button {
  margin-left: 15px; }

.modal .modal-dialog.modal-quick-start .modal_step_bottom {
  margin-top: 40px; }

.error-message {
  color: #a31616; }

#modal-alert {
  z-index: 2000; }

#cropper-modal-signature_file, #cropper-modal-_stamp_file {
  z-index: 2000; }

// TODO start Quick start: comment step 3 (type of payment reason) on customer demand
//#form_step_4 .form-group__label
// TODO end Quick start: comment step 3 (type of payment reason) on customer demand
#form_step_3 .form-group__label {
  font-size: 25px;
  font-weight: 700; }

#modal-touches {
  .modal__header {
    padding-bottom: 10px; }

  .modal__title {
    font-size: 36px; }

  .modal__inner {
    width: 90%;
    max-width: 1400px; }

  .modal__content .table-block {
    margin-top: 0; } }

@media (max-width: 768px) {
  .modal__inner {
    overflow: auto;
    max-height: 75%; }

  .modal__header {
    padding: 10px; } }

@import "modal-close";
@import "modal-header";
@import "modal-inputs";
@import "modal_buttons";

