//main styles for buttons

$btnHeight: 60px;
$btnHeightMobile: 30px;
$btnBorderWidth: 2px;

.button {
  height: $btnHeight;
  padding: 0 5px;
  border-radius: 30px;
  border-width: $btnBorderWidth;
  border-style: solid;
  text-align: center;
  font-family: $mainFont;
  font-size: 18px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 0.9px;
  transition: .2s ease;
  user-select: none;
  cursor: pointer;
  @include b(xs) {
    height: $btnHeightMobile;
    font-size: 13px; }

  &_small {}


  &_medium {}


  &_large {
    width: 100%;
    max-width: 186px; }

  &_link {
    height: initial;
    line-height: $btnHeight - ($btnBorderWidth * 2) - 1;
    display: inline-block;
    text-decoration: none; }
  &_center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .button {
      margin: 0 5px; } }
  &_icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    svg {
      transition: .2s ease; } } }



.buttons-bottom {
  margin-top: 40px; }

//buttons modificator
.button {
  background-color: $btnColor;
  border-color: $btnBorderColor;
  color: $btnTextColor;
  &:focus {
    text-decoration: none;
    @include b(desktop) {
      background-color: $btnColor;
      border-color: $btnBorderColor;
      color: $btnTextColor; } }
  &:hover {
    text-decoration: none;
    @include b(desktop) {
      background-color: $btnColorHover;
      border-color: $btnBorderColorHover;
      color: $btnTextColorHover; } }
  &:active {
    text-decoration: none;
    @include b(desktop) {
      background-color: $btnColorActive;
      border-color: $btnBorderColorActive;
      color: $btnTextColorActive;
      transform: translateY(1px); } }
  &.disable {
    pointer-events: none;
    cursor: default;
    color: $btnTextColorDisable;
    background-color: $btnColorDisable;
    border-color: $btnBorderColorDisable; } }

.button_dark {
  @extend button;
  background-color: $mainColor;
  color: #fff;
  &:hover {
    @include b(desktop) {
      background-color: $mainColorDark;
      border-color: $mainColorDark; } }
  &:focus {
    @include b(desktop) {
      background-color: $mainColor;
      border-color: $mainColor;
      color: #fff; } }
  &:active {
    @include b(desktop) {
      border-color: $mainColorDark;
      background-color: $mainColorDark; } } }



//on .dark-bg button styles
.dark-bg {
  .button,.button:focus {
    background: transparent;
    border-color: #fff;
    color: #fff;
    box-shadow: none;
    &:hover {
      background: #fff;
      border-color: #fff;
      color: #64855b; }
    &:active {
      background: #fff;
      border-color: #fff;
      color: #64855b; }
    &.disable {
      pointer-events: none;
      cursor: default;
      background: rgba(#f0f0f0, 0.5);
      border-color: rgba(#f0f0f0, 0.5);
      color: rgba(#fff, 0.5);
      box-shadow: none; } } }

.button_danger {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-width: 100px;
  color: #fff;
  border-color: #a94442;
  background-color: #a94442;
  margin-left: 15px; }

.button_danger:hover {
  background-color: red;
  border-color: red; }
