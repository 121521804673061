//add fonts

@each $key, $value in $includeFonts {
  @include font-face($mainFont, '../fonts/#{$key}/#{$key}', $value, normal, eot woff ttf svg); }

@each $key, $value in $includeSecondFonts {
  @include font-face($secondFont, '../fonts/#{$key}/#{$key}', $value, normal, eot woff ttf svg); }


html {
  height: 100%; }

body {
  font-family: $mainFont, sans-serif;
  font-size: $mainFontSize;
  font-weight: $mainFontWeight;
  color: $mainFontColor;
  line-height: $mainLineHeight;
  height: 100%;
 }  //overflow-y: scroll

a:focus, a:hover, a:active {
  text-decoration: none;
  outline: none; }


.disabled {
  pointer-events: none;
  opacity: 0.5; }


