.modal__smallText {
  font-size: 13px;
  line-height:  16px;
  color: #889290;
  a {
    color: #1d433d;
    &:hover {
      text-decoration: underline; } } }

@include bc((max: 579)) {
  .modal__smallText {
    font-size: 12px; } }
