.logoImg {
  width:  225px;
  height:  64px; }


@include bc((max: 991)) {
  .logoImg {
    width:  125px;
    height:  31px; } }

@include bc((max: 767)) {
  .logoImg {
    max-width: 100%;
    height:  auto; } }
