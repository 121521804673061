.avatar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: $mainFontColor;
  @extend .transition;
  &__logo {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-right: 0; }
  &__name {
    position: relative;
    font-size: 22px;
    letter-spacing: 0.66px;
    &:after {
      content: "";
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $mainColorLight;
      top:  calc(100% + 2px);
      left: 50%;
      transform: translateX(-50%);
      transition: .2s ease; } }

  &:hover {
    .avatar__name {
      color: $mainColorLight;
      &:after {
        width: 0; } } } }

@include bc((max: 991)) {
  .avatar {
    &__logo {
      width:  51px;
      height: 51px; } } }

@include bc((max: 767)) {
  .avatar {
    margin: 0;
    &__logo {
      display: none; }
    &__name {
      font-size: 14px;
      letter-spacing: 0.42px; } } }
