input::-webkit-outer-spin-button,
input::-webkit-inner-spin-butt {
  display: none;
  appearance: none;
  margin: 0; }

.form-group {
  margin-bottom: 20px; }

.form-group__label {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  font-family: $mainFont, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
  color: $labelColor;
  @include b(xs);
  &.dark-red {
    color: #8b0000; }
  &.dark-green {
    color: #006400; } }

.form-group_req {
  label:after {
    content: " *"; } }

.form-group_check-line {
  display: flex;
  margin-top: 15px;
  margin-bottom: 15px; }

.input-wrap {
  position: relative; }


.form-group, .input-wrap {
  position: relative;
  .error-block, .help-block-error {
    display: none;
    position: absolute;
    top: -15px;
    left: 0;
    width: 100%;
    background: $errorBlockBgColor;
    color: $errorBlockTextColor;
    font-family: $secondFont, sans-serif;
    line-height: 11px;
    font-size: 13px;
    text-align: right;
    font-weight: bold;
    z-index: 3; } }

.default-input,.text-area {
  width: 100%;
  padding: 0 20px;
  background-color: $inputBgColor;
  border: 1px solid $inputBorderColor;
  font-family: $secondFont, sans-serif;
  font-size: 16px;
  font-weight: 400;
  cursor: text;
  color: $inputTextColor;
  @extend .transition;
  @include b(xs) {
    padding: 0 20px; } }

.default-input {
  height: 52px;
  &:focus {
    background-color: $inputBgColorFocus;
    border-color: $inputBorderColorFocus; }
  &_textarea {
    min-height: 160px;
    padding-top: 15px;
    resize: none; } }

.has-ok {
  position: relative;
  .default-input,.text-area {
    border-width: 1px;
    border-color: $inputBorderColorSuccess; }
  .error-block, .help-block-error {
    display: none; } }

.has-error {
  .default-input-touch,.text-area {
    border-width: 1px;
    border-color: $inputBorderColorError;
    color: $inputTextColorError; }
  .error-block, .help-block-error {
    display: block; } }

.form-group.file-input {
  .error-block, .help-block-error {
    top: calc(100% + 5px);
    text-align: left; } }

.form-group.error-bottom {
  .error-block, .help-block-error {
    top: calc(100% + 5px);
    text-align: left; } }


input::-ms-clear,input::-ms-reveal,textarea::-ms-clear {
  display: none !important; }
input::-webkit-input-placeholder,textarea::-webkit-input-placeholder {
  color: $placeholderColor; }
input::-moz-placeholder,textarea::-moz-placeholder {
  color: $placeholderColor; }
input:-ms-input-placeholder,textarea:-ms-input-placeholder {
  color: $placeholderColor; }
input:-moz-placeholder,textarea:-moz-placeholder {
  color: $placeholderColor; }


.horizontal-block {
  display: flex;
  align-items: center;
  + .button {
    margin-bottom: 20px; }
  .form-group {
    min-width: 60%;
    margin-bottom: 5px; }
  .explanation {
    margin-left: 20px;
    padding-top:  20px;
    line-height: 1.2; } }



@include bc((max: 767)) {
  .form-group {
    margin-bottom: 20px;
    &__label {
      font-size: 13px; } }

  .form-group.file-input {
    .error-block, .help-block-error {
      top: 100%; } }

  .default-input {
    height:  42px;
    padding: 0 10px;
    font-size: 14px; }

  .form-group, .input-wrap {
    .error-block {
      font-size:  11px;
      top: 100%; } }

  .horizontal-block {
    display: block;
    .form-group {
      width: 100%;
      margin-bottom: 0; }
    .explanation {
      font-size: 13px;
      padding-top: 0;
      margin-left: 0;
      margin-top: 0;
      margin-bottom: 5px; } } }

@import "input-add";

.file-add-name {
  position: relative;
  margin-top: 10px;
  padding-left: 30px;
  font-weight: 700; }

.remove-file {
  margin-left: 10px;
  vertical-align: text-top;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 2px;
  svg {
    width: 15px;
    height: 15px;
    fill: red;
    transform: rotate(45deg); } }

.load-picture {
  width:  100px;
  height: 100px;
  display: flex;
  align-items: center;
  img {
    width: 100%; } }
