.link {
  transition: .2s ease;
  color: $mainFontColor;
  fill: $mainFontColor;
  cursor: pointer;

  &:hover {
    color: $mainColor;
    fill: $mainColor; }
  &:active {
    color: $mainColorDark;
    fill: $mainColorDark; }
  &:focus {
    @extend .link; }

  &_text {
    text-decoration: underline; } }
