input[type=checkbox] {
  display: none; }

.label_checkbox {
  position: relative;
  display: inline-block;
  width:  16px;
  height: 16px;
  border: 1px solid $mainColorDark;
  border-radius: 3px;
  margin-bottom: -3px;
  margin-left: 10px;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    display: none;
    width: 8px;
    height: 8px;
    top: 3px;
    left: 3px;
    background-color: $mainColorDark; } }


input[type=checkbox]:checked + .help-block-error + .label_checkbox:after,
input[type=checkbox]:checked + .help-block + .label_checkbox:after,
input[type=checkbox]:checked + .error-block + .label_checkbox:after,
input[type=checkbox]:checked + .label_checkbox:after {
  display: inline-block; }
