.radio-label {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer; }

.radio {
  display: inline-block;
  width:  15px;
  height:  15px;
  border: 1px solid $mainColorDark;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #fff;
  margin: 0 5px; }

input[type=radio] {
  opacity: 0;
  position: absolute;
  visibility: hidden;
  top: -9999px;
  left: - 99999px; }

input[type=radio]:checked + .radio, .radio.active {
     background-color: $mainColor; }
