//font

$includeFonts: ("Neuron-Regular": 400, "Neuron-Light": 300, "Neuron-DemiBold": 700);
$includeSecondFonts: ("Opensans": 400);

$mainFont: 'Neuron';
$mainFontSize: 16px;
$mainFontWeight: 400;
$mainFontColor: #373d41;
$mainLineHeight: 24px;

$secondFont: 'OpenSans';

// -------------------- colors

//main colors
$mainColor: #9eb670;
$mainColorLight: #9eb670;
$mainColorDark: #64855b;


$accentColor: #de1000;
$accentColorLight: #fd4b3e;

//success & errors
$successColor: #53bc53;
$errorColor: #a31616;

//------------------------------ links color
$linkColor: #0c70bd;
$linkColorHover: #de1000;
$linkColorActive: #de1000;
$linkColorFocus: $linkColor;

//----------------------- buttons colors --------------------------

//background color
$btnColor: #fff;
$btnColorHover: #9eb670;
$btnColorActive: #9eb670;
$btnColorFocus: #fff;
$btnColorDisable: #ccc;
//border color
$btnBorderColor: #9eb670;
$btnBorderColorHover: #9eb670;
$btnBorderColorActive: #9eb670;
$btnBorderColorFocus: $btnBorderColor;
$btnBorderColorDisable: #ccc;
//text color
$btnTextColor: #1d433d;
$btnTextColorHover: #ffffff;
$btnTextColorActive: #ffffff;
$btnTextColorFocus: $btnTextColor;
$btnTextColorDisable: #989898;


//------------------------------ select & input colors
$labelColor: #686868;
$placeholderColor: #686868;
$errorBlockTextColor: #a31616;
$errorBlockBgColor: transparent;

$inputBgColor: #f1f3f8;
$inputTextColor: #1d433c;
$inputBorderColor: #f1f3f8;

$inputBgColorFocus: #ffffff;
$inputTextColorFocus: #1d433c;
$inputBorderColorFocus: #9eb670;

$inputBgColorSuccess: #f1f3f8;
$inputTextColorSuccess: #1d433c;
$inputBorderColorSuccess: #f1f3f8;

$inputBgColorError: #fff;
$inputTextColorError: #1d433c;
$inputBorderColorError: #a31616;


//------------------------------ tabs colors
$tabBgColor: #fff;
$tabTextColor: #303030;
$tabBorderColor: #ffb800;

$tabBgColorHover: #fff;
$tabTextColorHover: #303030;
$tabBorderColorHover: #ffda7a;

$tabBgColorActive: #ffb800;
$tabTextColorActive: #fff;
$tabBorderColorActive: #ffb800;

$tabBgColorActiveClass: #ffb800;
$tabTextColorActiveClass: #fff;
$tabBorderColorActiveClass: #ffb800;

$tabBgColorDisable: #fff;
$tabTextColorDisable: #ccc;
$tabBorderColorDisable: #ffda7a;

//------------------------------------------

//------------------------------ help classes

.transition {
  transition: .0s ease-in; }
//------------------------------------------
