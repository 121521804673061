.modal {
  &__fieldWrap {
    position: relative; }

  &__inputLabel {
    position: absolute;
    display: inline-block;
    top: -3px;
    left: 5px; }

  &__inputLine {
    height:  2px;
    background-color: $mainColorDark;
    @extend .transition; }

  &__input {
    display: inline-block;
    width:  100%;
    font-size: 22px;
    letter-spacing: 1.1px;
    padding: 10px 5px;
    @extend .transition;
    &:focus + .modal__inputLine {
      background-color: $mainColor; } }


  //selects
  .selectric-wrapper {
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: $mainColorDark;
      position: absolute;
      top: 100%;
      left: 0;
      @extend .transition; }
    &.selectric-open:before {
      background-color: $mainColor; } }


  .selectric {
    background-color: transparent;
    border: none;
    padding-bottom: 3px;
    margin-top: 3px;
    .label {
      font-size:  22px;
      margin-right: 0;
      margin-left: 5px;
      color: $mainFontColor;
      padding: 0;
      text-align: left; }
    .button {
      width: inherit;
      height: inherit;
      display: inline-block;
      border: 0;
      right: -8px;
      top: 20px;
      font-size: 22px;
      color: $mainColor;
      &:after {
        display: none; } } }

  .selectric-items {
    background: #fff;
    border: none;
    margin-top: 2px;
    box-shadow: 0 0 10px -3px rgba(#000, .5);
    li {
      font-size: 22px;
      background-color: transparent;
      padding-left: 5px;
      color: $mainFontColor;
      @extend .transition;
      &:hover {
        background-color: $mainColor;
        color: #fff; } } }

  .selectric-open .selectric-items {
    transition: .3s ease-out; } }


//------------------------------ With Errors: add class 'has-error' to field-wrapper '.modal__fieldWrap'

.modal__fieldWrap.has-error {

  .modal__inputLine, .selectric-wrapper:before {
    background-color: red; }

  .error-block {
    position: absolute;
    top: 100%;
    left: 0;
    color: red;
    font-size: 11px;
    font-weight: 400;
    line-height: 1; } }

@include bc((max: 767)) {
  .modal {
    &__input {
      font-size:  18px; }

    .selectric {
      padding-bottom: 1px;
      margin-top: 0;
      .label {
        font-size:  18px; } }

    .selectric-items li {
      font-size:  18px; } } }
